import type { RefObject } from "react";
import Lottie from "react-lottie-player";

import lottieJson from "@Components/pages/common/LandingHero/scroll-down.json";

import { ArrowButton, ArrowWrapper } from "./styles";

type Props = {
  contentPageRef?: RefObject<HTMLDivElement | null>;
};

const ArrowDown = ({ contentPageRef }: Props) => {
  const handleArrowButtonClick = () => {
    if (contentPageRef?.current) {
      contentPageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <ArrowWrapper>
      <ArrowButton data-testid="arrow-down" onClick={handleArrowButtonClick}>
        <Lottie animationData={lottieJson} loop play style={{ height: 40, width: 40 }} />
      </ArrowButton>
    </ArrowWrapper>
  );
};

export default ArrowDown;
