import type { ReactElement } from "react";
import { useEffect, useRef, useState } from "react";

import { VisibilityWrapper } from "../styles";

type Props = {
  id?: string;
  children?: ReactElement;
  onVisible?: () => void;
  onEnter?: () => void;
  onLeave?: () => void;
  offset?: number; // this prop is defines how much of a component should be in the view port from top or bottom
  once?: boolean; // if once is set to true, onVisible will be fired only once on component fully visible
};

const VisibilitySensor = ({ id, children, onVisible, offset = 100, onEnter, onLeave, once = true }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isSeen, setIsSeen] = useState(false);
  const [hasEntered, setHasEntered] = useState(false);

  useEffect(() => {
    const handleOnEnter = () => {
      if (onEnter && ref?.current) {
        const rect = ref.current.getBoundingClientRect();
        const fromDown = rect.top > 0 ? window.innerHeight - rect.top > offset : false;
        const fromUp = rect.bottom > offset && rect.top <= 0;
        if ((fromDown || fromUp) && !hasEntered) {
          setHasEntered(true);
          onEnter();
        }
      }
    };
    const handleOnLeave = () => {
      if (onLeave && ref?.current) {
        const rect = ref.current.getBoundingClientRect();
        if (rect.top > window.innerHeight || rect.bottom - offset <= 0) {
          setHasEntered(false);
          onLeave();
        }
      }
    };
    const handleVisibility = () => {
      if (onVisible && ref?.current) {
        const rect = ref.current.getBoundingClientRect();
        if (
          !isSeen &&
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= window.innerHeight &&
          rect.right <= window.innerWidth
        ) {
          if (once) {
            setIsSeen(true);
          }
          onVisible();
        }
      }
    };
    const handleScroll = () => {
      handleVisibility();
      handleOnEnter();
      handleOnLeave();
    };
    handleVisibility();
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, [ref, onVisible, isSeen, offset, hasEntered, onEnter, onLeave, once]);

  return (
    <VisibilityWrapper id={id} ref={ref}>
      {children}
    </VisibilityWrapper>
  );
};

export default VisibilitySensor;
