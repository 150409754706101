"use client";

import styled from "styled-components";
import { colors, rem } from "@boxt/design-system";

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const List = styled.ul`
  padding-left: ${rem(16)};
`;

export const ListItem = styled.li`
  list-style: disc;
  color: ${colors.slate.normal};
  font-weight: 300;

  &:not(:last-child) {
    margin-bottom: ${rem(16)};
  }
`;
