"use client";

import styled, { css } from "styled-components";
import tinyColor from "tinycolor2";
import type { FontTheme } from "@boxt/design-system";
import { breakpoint, includeSpacing, Paragraph, rem } from "@boxt/design-system";

import withCustomWrapper from "@Helpers/withCustomWrapper";
import { Products } from "@Collections/products";
import Image from "@Components/Image";
import { ReactComponent as TickIcon } from "@Images/icons/border-tick.svg";

export const Subheading = withCustomWrapper(
  Paragraph,
  ({ children, size, $boldTheme }: { children: string; size; $boldTheme: FontTheme }) => (
    <Paragraph as="strong" boxtTheme={$boldTheme} key={children} size={size} weight="bold">
      {children}
    </Paragraph>
  ),
);

export const Container = styled.div`
  position: relative;
  height: ${rem(600)};
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);

  ${breakpoint("md")} {
    height: ${rem(720)};
  }

  ${breakpoint("lg")} {
    height: ${rem(650)};
  }
`;

export const ContentContainer = styled.div<{
  $color: string;
  $opacity: number;
  $blur: string;
  $productType?: Products;
}>`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ $color, $opacity }) => tinyColor($color).setAlpha($opacity).toRgbString()};
  backdrop-filter: blur(${({ $blur }) => $blur});
  right: 0;
  border-radius: ${rem(12)} ${rem(12)} 0 0;

  ${includeSpacing({ mr: { lg: 5, md: 0 } })}

  ${breakpoint("md")} {
    border-radius: ${rem(24)} ${rem(24)} 0 0;
  }

  ${breakpoint("lg")} {
    border-radius: ${rem(6)};
    align-items: flex-start;
    max-width: ${rem(670)};
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;

    ${({ $productType }) =>
      $productType === Products.BoilerRental &&
      css`
        width: 980px;
        height: 500px;
        mask-size: 100% 100%;
        mask-image: url(/_next-public/images/boiler-subscription/arrow-mask.png);
      `};
  }
`;

export const VanWrapper = styled.div<{ $productType: Products }>`
  z-index: 1;
  position: absolute;
  top: ${({ $productType }) => ($productType === Products.BoilerRental ? rem(25) : rem(-38))};
  transform: ${({ $productType }) => ($productType === Products.BoilerRental ? "scale(0.9, 0.9)" : "scale(0.7, 0.7)")};
  ${breakpoint("md")} {
    top: ${({ $productType }) => ($productType === Products.BoilerRental ? "1.5rem" : "-2.18rem")};
    transform: ${({ $productType }) => ($productType === Products.BoilerRental ? "scale(1, 1)" : "scale(0.9, 0.9)")};
  }
  ${breakpoint("lg")} {
    top: ${({ $productType }) => ($productType === Products.BoilerRental ? rem(25) : rem(-30))};
    transform: scale(1, 1);
    left: ${({ $productType }) => ($productType === Products.BoilerRental ? rem(65) : rem(34))};
  }
`;

export const UpperTextWrapper = styled.div`
  ${includeSpacing({ mb: 3, mh: { lg: 5, sm: 0 }, mt: { lg: 4, md: 2, sm: 3 } })}
`;

export const LowerTextWrapper = styled.div<{ $color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div > h5 {
    color: ${({ $color }) => $color};
  }

  ${breakpoint("lg")} {
    align-items: flex-start;
  }

  ${includeSpacing({ mb: { lg: 4, md: 3, sm: 2 }, ml: { lg: 4, sm: 0 }, mt: { md: 3, sm: 2 } })}
`;

export const BenefitWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomIconContainer = styled.div`
  display: none;
  ${breakpoint("lg")} {
    display: flex;
  }
`;

export const Tick = styled(TickIcon)`
  color: transparent;
  left: 0;
  display: none;

  ${breakpoint("lg")} {
    display: inherit;
    transform: scale(0.7, 0.7);
  }
`;

export const HeroImage = styled(Image)`
  object-position: top;

  ${breakpoint("lg")} {
    object-position: initial;
  }
`;

export const HeroImageTopPositioned = styled(Image)`
  object-position: top;
`;

export const HeaderIconContainer = styled.div`
  display: flex;
  justify-content: center;
  ${breakpoint("lg")} {
    justify-content: flex-start;
  }
`;

export const PostcodeContainer = styled.div`
  ${breakpoint("sm")} {
    width: 100%;
  }

  ${breakpoint("md")} {
    max-width: ${rem(670)};
  }

  ${breakpoint("lg")} {
    align-self: center;
    width: 90%;
  }
`;
