import type { DOMNode, Element } from "html-react-parser";
import { domToReact } from "html-react-parser";
import { Button } from "@boxt/design-system";

import { scrollTop } from "@Helpers/scrollTop";
import type { FONT_COLOR } from "@Collections/themeColors";
import { getDOMParserOptions } from "@Components/pages/common/HTMLParser/helpers";

import type { IHTMLParserComponentMapConfig } from "../config";

type Props = {
  $domNode: Element;
  $componentMap: IHTMLParserComponentMapConfig;
  $headingTheme: keyof typeof FONT_COLOR;
};

const ParsedLink = ({ $domNode, $componentMap, $headingTheme }: Props) => {
  if ($domNode?.attribs?.href === "#") {
    return (
      <Button boxtTheme="coral" onClick={scrollTop} skin="link">
        {domToReact(
          $domNode.children as DOMNode[],
          getDOMParserOptions({ componentMap: $componentMap, contentTheme: "coral", headingTheme: $headingTheme }),
        )}
      </Button>
    );
  } else {
    return (
      <Button
        boxtTheme="coral"
        href={$domNode?.attribs?.href}
        rel="noopener noreferrer"
        skin="link"
        {...($domNode?.attribs?.href?.startsWith("#") ? {} : { target: "_blank" })}
      >
        {domToReact(
          $domNode.children as DOMNode[],
          getDOMParserOptions({ componentMap: $componentMap, contentTheme: "coral", headingTheme: $headingTheme }),
        )}
      </Button>
    );
  }
};

export default ParsedLink;
