import { useCallback } from "react";
import type { CSSObject } from "styled-components";
import type { Element } from "html-react-parser";

import ImageWithSizes from "@Components/pages/common/HTMLParser/ParsedImage/ImageWithSizes";

type Props = {
  $domNode: Element;
  $styleObject: CSSObject;
  alt?: string;
};

export type Alignment = "left" | "right" | "center";

const ParsedImage = ({ $domNode, $styleObject, alt = "" }: Props) => {
  const attribs = $domNode?.attribs;
  const sizes = {
    height: parseInt(attribs?.height) || 0,
    width: parseInt(attribs?.width) || 0,
  };
  const getAlignment = useCallback(() => {
    let alignment: Alignment | undefined;
    if ($styleObject.marginLeft === "auto" && $styleObject.marginRight === "auto") {
      alignment = "center";
    } else if (attribs?.class?.includes("butter-float-right")) {
      alignment = "right";
    } else if (attribs?.class?.includes("butter-float-left")) {
      alignment = "left";
    }

    return alignment;
  }, [$styleObject, attribs]);

  return (
    <ImageWithSizes alignment={getAlignment()} alt={alt} src={attribs?.src} {...sizes} styleObject={$styleObject} />
  );
};

export default ParsedImage;
