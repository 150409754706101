import { Button, Grid, H3, Spacing } from "@boxt/design-system";

import Card from "./components/Card";
import { CardWrapper, Container } from "./styles";
import type { ButterWaysToPayFields } from "./types";

type Props = {
  fields: ButterWaysToPayFields;
};

const WaysToPay = ({ fields }: Props) => {
  const { bg_color, title, cards, cta_text, cta_link, cta_theme, cta_skin, id } = fields;

  return (
    <Container $bgColor={bg_color?.name} id={id}>
      <Spacing mv={{ lg: 6, md: 4 }}>
        <Grid>
          <Grid.Row>
            <Grid.Col lg={{ offset: 2, span: 12 }}>
              <H3>{title}</H3>
              <CardWrapper mb={4} mt={{ md: 3 }}>
                {cards.map((card, index) => (
                  <Card cardData={card} isFirst={index === 0} key={card.text} />
                ))}
              </CardWrapper>
              {cta_text && (
                <Button boxtTheme={cta_theme?.theme} href={cta_link} skin={cta_skin || "primary"}>
                  {cta_text}
                </Button>
              )}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Spacing>
    </Container>
  );
};

export default WaysToPay;
