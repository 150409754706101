import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { Button, Paragraph } from "@boxt/design-system";

import { CUSTOMER_SERVICE_PHONE } from "@Collections/client";
import { CUSTOMER_URLS, REPAIR_URLS } from "@Collections/routes";
import useChat from "@Hooks/useChat";

import { InfoContainer, List, ListItem } from "./styles";

export type Props = {
  i18n: {
    key: string;
    namespace: string;
  };
};

const MoreInfo = ({ i18n: { namespace, key } }: Props) => {
  const { t } = useTranslation(namespace);
  const content: Record<string, string>[] = t(`${key}.content`, {}, { returnObjects: true });
  const { openChat } = useChat();

  return (
    <InfoContainer>
      {Object.keys(content).map((k) => (
        <Trans
          components={{
            fullList: (
              <Button
                boxtTheme="coral"
                href={REPAIR_URLS.LANDING_PRICES}
                rel="noopener noreferrer"
                skin="link"
                target="_blank"
              />
            ),
            landing: (
              <Button
                boxtTheme="coral"
                href={REPAIR_URLS.LANDING}
                rel="noopener noreferrer"
                skin="link"
                target="_blank"
              />
            ),
            li: <ListItem />,
            livechat: (
              <Button
                boxtTheme="coral"
                onClick={() => openChat()}
                rel="noopener noreferrer"
                skin="link"
                target="_blank"
              />
            ),
            number: (
              <Button boxtTheme="coral" href={`tel://${CUSTOMER_SERVICE_PHONE.replace(/\s/g, "")}`} skin="link" />
            ),
            p: <Paragraph align="left" bottom={4} boxtTheme="slate" />,
            questionnaire: (
              <Button
                boxtTheme="coral"
                href={CUSTOMER_URLS.BOILER}
                rel="noopener noreferrer"
                skin="link"
                target="_blank"
              />
            ),
            s: <strong />,
            ul: <List />,
          }}
          i18nKey={`${namespace}:${key}.content.${k}`}
          key={k}
        />
      ))}
    </InfoContainer>
  );
};

export default MoreInfo;
