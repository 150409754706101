"use client";

import styled, { css } from "styled-components";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

const radius = rem(10);

const FlexTemplate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  border-bottom: 1px solid ${colors.platinum.light};
  width: 100vw;
  height: 100%;
  position: relative;
  overflow: hidden;
  color: ${colors.slate.normal};
  background: ${colors.white};
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;

export const Title = styled(FlexTemplate)`
  flex-direction: column;
  ${includeSpacing({
    mb: 4,
    mt: { md: 5, sm: 4 },
  })}

  h4 {
    text-align: center;
  }
`;

export const Slide = styled(FlexTemplate)`
  height: 100%;
  width: 100%;
  flex-direction: column;
  border-radius: ${radius};
  background-color: ${colors.slate.lightest};
`;

export const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: ${radius};
`;

export const ContentWrapper = styled(FlexTemplate)`
  flex-direction: column;
  ${includeSpacing({
    mh: 2,
  })}
  p {
    min-height: ${rem(120)};
    ${breakpoint("lg")} {
      min-height: unset;
    }
  }
`;

export const SliderCol = styled.div`
  ${includeSpacing({
    mb: { lg: 4, md: 0 },
  })}
`;

export const DotsStyle = css`
  ${includeSpacing({
    mb: { md: 2, sm: 2 },
    mt: 3,
  })};
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
