import type { RefObject } from "react";
import type { FontTheme } from "@boxt/design-system";

import type { Products } from "@Collections/products";
import type { ButterComponent } from "@Components/pages/content/types";
import type { ScreenerVariant } from "@Pages/api/_next/screeners/types";

export enum ImageFolder {
  Repair = "boiler-repair",
  HomeCover = "home-cover",
  HeatLink = "heatlink-repair",
  BoilerSubscription = "boiler-subscription",
  Boiler = "boilers",
  Solar = "solar",
}

export type Breakpoints = "sm" | "md" | "lg";

export type LandingHeroContent = {
  background_color: string;
  background_opacity: string;
  background_blur: string;
  background_image_quality: string;
  background_image_desktop: string;
  background_image_alt: string;
  background_image_tablet: string;
  background_image_mobile: string;
  benefits: { benefit: string }[];
  benefits_color: string;
  benefits_icon: string;
  bottom_postcode_cta: {
    background_image: string;
    header: string;
    placeholder: string;
    cta_text: string;
    cta_theme: {};
  };
  cta_color: string;
  error_bubble_bg_color: string;
  error_bubble_text_theme: string;
  faq: {
    header: string;
    i18n_namespace: string;
    google_analytics_category: string;
    categories: [];
  };
  header_image: string;
  heading: string;
  heading_theme: string;
  heading_theme_strong: string;
  id: string;
  image_tiles: {
    header: string;
    id: string;
    tiles: [];
  };
  left_overlay_image: string;
  modules: ButterComponent[];
  subheading: string;
  van_image_quality: string;
  van_image: string;
  van_image_alt: string;
  why_section: {
    header: string;
    header_theme: {};
    secondary_text: string;
    video_background: string;
    image_5050: string;
    content_header_5050: string;
    content_logo_5050: string;
    content_bullet_point_icon_5050: string;
    main_cta_text: string;
    main_cta_theme: {};
    secondary_cta_text: string;
    secondary_cta_theme: {};
    secondary_cta_url: string;
    content_bullet_points_5050: [];
    background: [];
  };
  show_arrow_down: boolean;
  slider_with_cta: {
    header: string;
    cta_text: string;
    cta_theme: {};
    slides: [];
  };
};

export type Props = {
  content: LandingHeroContent;
  contentPageRef?: RefObject<HTMLDivElement | null>;
  i18nNamespace: string;
  imageFolder: ImageFolder;
  onSubmit: (_: any) => Promise<any>;
  productType: Products;
  hasAnalyticEventOnSubmit?: Boolean;
  screeningId?: string;
  onFinishQuote?: () => void;
  variant?: ScreenerVariant;
  initialPostcode?: string;
  onStartQuote?: () => void;
  isHeatlink?: boolean;
};

export type TextContent = {
  benefits: Record<string, string>[];
  heading: string;
  subheading: string;
};

export type UiContent = {
  background_blur: string;
  background_color: string;
  background_image_desktop: string;
  background_image_alt: string;
  background_image_mobile: string;
  background_image_quality: number;
  background_image_tablet: string;
  background_opacity: string;
  benefits_color: string;
  benefits_tick_color: string;
  cta_color: string | null;
  heading_theme: FontTheme;
  heading_theme_strong: FontTheme;
  van_image?: string;
  van_image_alt?: string;
  van_image_quality: number;
  error_bubble_bg_color: string;
  error_bubble_text_theme: FontTheme;
  left_image_overlay?: string;
  left_image_overlay_alt?: string;
  left_image_overlay_quality?: number;
};

export type Content = TextContent & UiContent;
