import styled from "styled-components";
import { breakpoint, includeSpacing, rem } from "@boxt/design-system";

export const ArrowWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  z-index: 10;
  align-items: center;

  ${breakpoint("lg")} {
    position: absolute;
    bottom: ${rem(16)};
  }
`;

export const ArrowButton = styled.button`
  border-radius: 20px;
  border: unset;
  padding: 0;
  cursor: pointer;
  ${includeSpacing({ mb: { lg: 0, md: 2, sm: 2 } })}
`;
